import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'

const MediaViewer = ({ src, playing }) => {
	const [fileType, setFileType] = useState(null)

	const playerRef = useRef(null)

	useEffect(() => {
		if (playerRef.current) {
			playerRef.current.seekTo(0)
		}
	}, [playing])

	useEffect(() => {
		const fileType = src.split('.').pop().toLowerCase()
		setFileType(fileType)
	}, [src])

	let mediaContent;
	if (fileType === 'gif') {
		if (playing) {
			mediaContent = <img src={src} alt='GIF' />
		} else {
			mediaContent = <img src='' alt='GIF' />
		}
	} else if (fileType === 'mp4') {
		mediaContent = (
			<ReactPlayer
				ref={playerRef}
				url={src}
				muted
				loop
				playing={playing}
				playsinline
			/>
		)
	} else {
		mediaContent = <p>Unsupported file type</p>
	}

	return <div className='media'>{mediaContent}</div>
}

export default MediaViewer