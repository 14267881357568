import { Routes, Route } from 'react-router-dom'
import { useState, useEffect } from 'react'

import './styles/spacing/space.scss'
import './styles/01-default.css'
import './styles/02-styleguide.css'
import './styles/03-main.css'

import GlobalAnimations from './animations/GlobalAnimations.jsx'
import Home from './routes/home/home.jsx'
import Navigation from './routes/navigation/navigation.jsx'
import About from './routes/about/about.jsx'

const App = () => {

	return (
		<Routes>
			<Route path='/' element={ <GlobalAnimations /> }>
				<Route path='/' element={ <Navigation /> }>
					<Route path='/' element={ <Home /> }>
						<Route path='/about-me' element={ <About /> }></Route>
					</Route>
				</Route>
			</Route>
		</Routes>
	)
}

export default App