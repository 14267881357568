import { useLayoutEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import MediaViewer from '../media-viewer'

const Project = ({ project, number }) => {

	const { projectName, filePath, year, keywords, createdAt } = project

	const componentRef = useRef(null)

	const [playing, setPlaying] = useState(false)

	useLayoutEffect(() => {

		const component = componentRef.current	

		let ctx = gsap.context(() => {

			let mm = gsap.matchMedia()

			const project = component.querySelector('.project')
			const projectDetails = component.querySelector('.projectDetails')
			const media = component.querySelector('.media')

			mm.add("(min-width: 1250px)", () => {
				setPlaying(false)

				const projectHover = gsap.timeline({ paused: true })

				projectHover
					.to(projectDetails, {duration: 1, opacity: 1, overwrite: 'auto', ease: 'none'}, 0)
					.to(media, {duration: 1, opacity: 1, ease: 'power1.in'}, 0)
					.from(media, {duration: 1, scale: 0.96, ease: 'power3.out'}, 0)
				;

				project.addEventListener('mouseenter', () => {
					projectHover.play()
					setPlaying(true)
				})

				project.addEventListener('mouseleave', () => {
					projectHover.reverse(-1)
					setPlaying(false)
				})
			})

			mm.add("(max-width: 1249px)", () => {
				setPlaying(true)
			})

		})

		return () => ctx.revert()

	}, [])
	
	return (
		<div ref={componentRef} className='projectContainer flex-column align-center'>
			<div className='project m-tb-auto flex-center'>
				<div className='line b1'></div>
			</div>
			<span className='number font-default c1'>0{number + 1}</span>
			<div className='projectDetails c1 flex font-default'>
				<span className='projectName'>{projectName}</span>
				<div className='keywords flex'>
					{keywords.map((keyword, index) => {
						return (
							<span key={index}>{keyword}</span>
						)
					})}
				</div>
				<span className='createdAt'>{createdAt}</span>
				<span className='year'>{year}</span>
			</div>
			<MediaViewer src={filePath} playing={playing} />
		</div>
	)
}

export default Project