import { Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Navigation = () => {

	return (
		<>
			<div className='navigation b2 c1 flex space-between'>
				<Link to='/about-me'>About me +</Link>
				<a href='mailto:linsey.dolleman(@)gmail.com' title='E-Mail'>linsey.dolleman(@)gmail.com</a>
			</div>
			<Outlet />
		</>
	)
}

export default Navigation