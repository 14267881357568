import { Outlet } from 'react-router-dom'
import { useLayoutEffect } from 'react'
import { gsap } from 'gsap'
import { useLocation } from 'react-router-dom';

const GlobalAnimations = () => {
	
	useLayoutEffect(() => {

		let themes = ['theme1', 'theme2', 'theme3', 'theme4', 'theme5']

		const applyRandomTheme = () => {
			const randomIndex = Math.floor(Math.random() * themes.length)
			const randomTheme = themes[randomIndex]
			document.body.classList.add(randomTheme)

			//? change favicon
			const links = document.head.querySelectorAll('link')

			links.forEach(link => {
				let href = link.getAttribute('href')

				if (href.includes('fallback')) {
					href = href.replace('fallback', randomTheme)
					link.setAttribute('href', href)
				}
			})
		}

		applyRandomTheme()

		let ctx = gsap.context(() => {

			let mm = gsap.matchMedia()

			//! loader 

			const main = document.querySelector('main')
			const navigation = document.querySelector('.navigation')
			const name = document.querySelector('.name')
			const nameTxt = document.querySelectorAll('.name span')
			const firstName = document.querySelector('.firstName')
			const projects = document.querySelector('.projects')
			const projectLines = document.querySelectorAll('.project .line')
			const projectNumbers = document.querySelectorAll('.number')

			gsap.set(main, {pointerEvents: 'none'})
			

			mm.add("(min-width: 1250px)", () => {
				const initialLoader = gsap.timeline({ paused: true })

				initialLoader
					.from(name, {duration: 1, opacity: 0, ease: 'none'}, 0)
					.to(firstName, {duration: 2, marginRight: 'auto', ease: 'expo.out'}, '>')
					.to(projectLines, {duration: 1.5, height: '100%', ease: 'expo.out'}, '<')
					.from([navigation, projectNumbers], {duration: 1, opacity: 0, ease: 'none'}, '>-1')
					.set(main, {pointerEvents: 'all'})
				;

				initialLoader.play(0)
			})

			mm.add("(max-width: 1249px)", () => {

				const initialLoader = gsap.timeline({ paused: true })

				initialLoader
					.from(nameTxt, {duration: 1, stagger: .2, opacity: 0, ease: 'none'}, 0)
					.from(nameTxt, {duration: 2, stagger: .2, yPercent: 100, ease: 'expo.out'}, 0)
					.from([navigation, projects], {duration: 1, stagger: .333, opacity: 0, ease: 'none'}, 1)
					.set(main, {pointerEvents: 'all'}, 1)
				;

				initialLoader.play(0)
			})

		})

		return () => ctx.revert()

	}, [])

	const location = useLocation()

	useLayoutEffect(() => {

		let ctx = gsap.context(() => {
			
			//! mouse 
			const mouse = document.querySelector('.mouse')
			const cursor = document.querySelector('.cursor')

			//* hover elements
			function createHoverElements() {
				const cursorHover = gsap.to(cursor, {duration: 0.333, width: 40, height: 40, opacity: 0.4, ease: 'power1.inOut', paused: true})

				const hoverElements = document.querySelectorAll('a, .hoverElement, label, select, input, textarea, button')
				hoverElements.forEach(hover => {
					hover.addEventListener('mouseenter', function(){
						cursorHover.play()
					})
					hover.addEventListener('mouseleave', function(){
						cursorHover.reverse()
					})
				})

				cursorHover.reverse()
			}

			if(!window.matchMedia('(pointer: coarse)').matches) {
				gsap.set(mouse, {display: 'block'})

				//* hover elements
				createHoverElements()

				//* move mouse
				function moveMouse(e) {
					gsap.set(mouse, {
						left: e.clientX,
						top: e.clientY
					})
				}

				document.addEventListener('mousemove', moveMouse)

				//* hide/show mouse on page leave/enter
				document.addEventListener('mouseleave', function(){
					gsap.set(mouse, {opacity: 0})
				})
				
				document.addEventListener('mouseenter', function(){
					gsap.set(mouse, {opacity: 1})
				})
			}
		})

		return () => ctx.revert()

	}, [location.pathname])

	return (
		<>
			<div className="mouse">
				<div className="cursor"></div>
			</div>
			<Outlet />
		</>
	)
}

export default GlobalAnimations;