import { Outlet } from 'react-router-dom'
import { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Projects from "../../components/projects/projects"

gsap.registerPlugin(ScrollTrigger)

const Home = () => {

	const componentRef = useRef(null)

	useLayoutEffect(() => {

		const component = componentRef.current

		let ctx = gsap.context(() => {

			const name = component.querySelector('.name')

			let mm = gsap.matchMedia()

			mm.add("(max-width: 1249px)", () => {
				const nameFade = gsap.timeline({
					scrollTrigger: {
						trigger: name,
						scroller: '.projects',
						start: '20% 20%',
						end: '100% top',
						scrub: true,
						markers: false,
						id: 'contact'
					}
				})

				nameFade
					.to(name, {duration: 1, opacity: 0, ease: 'none'})
				;
			})

		})

		return () => ctx.revert()

	}, [])

	return (
		<main ref={componentRef} className='flex-column b2'>
			<div className='name tac t1 c1 flex-center'>
				<span className='firstName'>Linsey</span>
				<span className='spacer'>&nbsp;</span>
				<span className='lastName'>Dolleman</span>
			</div>
			<Projects />
			<Outlet />
		</main>
	)

}

export default Home
